import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const ProdutoTable = EgretLoadable({
  loader: () => import("./ProdutoTable")
});

const produtoRoute = [
  {
    path: "/produto",
    exact: true,
    component: ProdutoTable,
    auth: authRoles.admin
  }
];

export default produtoRoute;
