import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const ClienteTable = EgretLoadable({
  loader: () => import("./ClienteTable")
});

const clienteRoute = [
  {
    path: "/cliente",
    exact: true,
    component: ClienteTable,
    auth: authRoles.admin
  }
];

export default clienteRoute;
