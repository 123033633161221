import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const CategoriaTable = EgretLoadable({
  loader: () => import("./CategoriaTable")
});

const categoriaRoute = [
  {
    path: "/categoria",
    exact: true,
    component: CategoriaTable,
    auth: authRoles.admin
  }
];

export default categoriaRoute;
